<template>
  <a class="no-underline" href="https://tozny.com/contact" target="_blank">
    <ToznyButton
      class="banner-button mb-0 ml-4 focus:outline-none active:outline-none"
      id="contact-sales-button-in-banner"
      buttonText="Contact Sales"
    />
  </a>
</template>

<script>
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'NotificationsContactSalesButton',
  components: {
    ToznyButton,
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

#contact-sales-button-in-banner {
  background-color: $colors-tozstore;
  border: 2px solid $white;
  white-space: nowrap;
}

#contact-sales-button-in-banner:hover {
  background: $white;
  border-radius: 6px;
  border: 2px solid $theme-green-active;
  color: $theme-green;
}
</style>
